import { _js } from '@ifixit/localize';
import { SingleFieldEditor } from 'Guide/FrameModules/single_field_editor';
import { Auth } from 'Shared/auth';
import { tween } from 'shifty';

onDomReady(() => {
   $('content').addEvents({
      'keyup:relay(#answersSearch)': function (ev, el) {
         if (ev.key == 'enter') {
            const input = document.querySelector('#answersSearch');
            const baseUrl = input.dataset.searchUrl;
            const search = input.value;

            executeSearch(baseUrl, search);
         }
         return;
      },
      'click:relay(.question-unsubscribe-container)': function (ev, el) {
         let postid = el.get('data-postid');
         let title = el.get('data-post-title');

         Auth.required({
            onAuthorize: function () {
               new Request.AjaxIO('watch', {
                  url: '/Answers/View/' + postid + '/' + title,
                  onSuccess: function () {
                     let questionRow = el.getParent('.question-row');

                     tween({
                        from: {
                           height: questionRow.scrollHeight,
                           padding: questionRow.style.padding,
                           opacity: 1,
                        },
                        to: {
                           height: 0,
                           padding: 0,
                           opacity: 0,
                        },
                        easing: 'easeOutQuad',
                        duration: 300,
                        render: ({ height, padding, opacity }) => {
                           questionRow.style.height = height + 'px';
                           questionRow.style.padding = padding + 'px';
                           questionRow.style.opacity = opacity;
                        },
                     }).then(() => {
                        questionRow.destroy();
                     });
                  },
               }).send('off', 'post', postid);
            },
         });
      },
   });

   $$('.js-edit-question-title').each(el => {
      let postid =
         el.get('data-postid') || el.getParent('.js-edit-question-title').get('data-postid');
      new SingleFieldEditor(el, null, {
         width: '95%',
         field_title: _js('Edit answer title'),
         field: 'answers_title_' + postid,
         hideUntilHover: true,
      });
   });

   function executeSearch(baseUrl, search) {
      const url = new URL(baseUrl);
      url.searchParams.set('search', search);
      window.location = url;
   }

   $$('.js-toggle-selected').addEvent('click', function (ev) {
      let isSelected = this.hasClass('selected');
      $$('.js-toggle-target').removeClass('toggle-open');
      $$('.js-toggle-selected').removeClass('selected');

      let target = this.getParent().getElement('.js-toggle-target');
      target.toggleClass('toggle-open', !isSelected);
      this.toggleClass('selected', !isSelected);
   });
});
